import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Section, Typography, Icon } from '../../components/library'
import theme from '../../theme'

function section() {
    return (
        <LibraryWrapper>
                <Section 
                    actionLabel="Add a symptom"
                    actionOnClick={(e) => console.log('CLICK ACTION')}
                >
                    <Typography variant="body1" align="left">
                        No symptoms reported
                    </Typography>
                </Section>
                <hr />
                <Section 
                    title="My Goal" 
                    icon={<Icon name="goals" />} 
                    footer={<Typography variant="caption">Earn up to 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
                    actionLabel="GOAL CHECK-IN"
                    actionTo="/components"
                >
                    <Typography variant="body1" align="left">
                        Check in with parent about asthma symptoms 5 times this week.
                    </Typography>
                </Section>
                <hr />
                <Section 
                    title="Spirometry Test" 
                    icon={<Icon name="spirometer" bg={theme.palette.pink.lighter} fg={theme.palette.pink.main} />} 
                    actionLabel="Test now"
                    footer={<Typography variant="caption">You can earn 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
                >
                    <Typography variant="body1" align="left">
                        Take the spirometry test with your spirometer.
                    </Typography>
                </Section>
                <hr />
                <Section 
                    title="C-ACT" 
                    icon={<Icon name="spirometer" bg={theme.palette.blue.lighter} fg={theme.palette.blue.main} />} 
                    actionLabel="Test now"
                    
                    footer={<Typography variant="caption">You can earn 1x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
                >
                    <Typography variant="body1" align="left">
                        Take the Childhood Asthma control Test questionnaire for week 2.
                    </Typography>
                </Section>
                <hr />
                <Section 
                    title="DAILY CHECK-IN" 
                    actionLabel="GOAL CHECK-IN"
                    editTo="/components"
                    footer={<Typography variant="caption">Earn up to 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
                >
                    <Typography variant="body1" align="left">
                        Check in with parent about asthma symptoms 5 times this week.
                    </Typography>
                </Section>
                <hr />
                <Section 
                    title="MEDICATION" 
                    actionLabel="GOAL CHECK-IN"
                    editTo="/components"
                    footer={<Typography variant="caption">Earn up to 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
                >
                    <Typography variant="body1" align="left">
                        Take daily medication
                    </Typography>
                    <ul>
                        <li><Typography variant="caption" align="left">Remember to take AM medication 3 times</Typography></li>
                        <li><Typography variant="caption" align="left">Remember to take PM medication 3 times</Typography></li>
                    </ul>
                </Section>
        </LibraryWrapper>
    )
}
export default section
